import React from 'react';
import PropTypes from 'prop-types';
import PropertyDetailed from 'components/properties/PropertyDetailed';
import useStyles from 'styles/pages/ViewListingsDetails';

const ViewListingsDetailsPage = ({ location }) => {
	const uri = location.search;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<PropertyDetailed sellPage={false} uri={uri.substring(1)} />
		</div>
	);
};

ViewListingsDetailsPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default ViewListingsDetailsPage;
